import { parseVesselBookmark, prepareQueryParamsForVesselRequest } from '~/utils/vessel.util';

export const useVesselsStore = defineStore('vesselsStore', {
  state: () => ({
    showFiltersSideModal: false,
    vessels: [],
    total: undefined,
    page: 1,
    per_page: 20,
    isFetching: false,
  }),
  actions: {
    async fetchVessels(routeQuery: any) {
      try {
        this.isFetching = true;
        const userStore = useUserStore();
        if (!userStore.activeOrgId) {
          return { containers: [], total: 0, page: 1, per_page: 20 };
        }
        const { $ofetch }: any = useNuxtApp();
        const queryParams: string = prepareQueryParamsForVesselRequest(routeQuery, userStore.activeOrgId);
        const url = `/api/vessels/list?${queryParams}`;
        const { results, total, page }: any = await $ofetch(url, { method: 'GET' });
        this.vessels = results.map((container: any) => parseVesselBookmark(container));
        this.total = total;
        this.page = page;
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.isFetching = false;
      }
    },
    async fetchVesselsCbolb(id: any) {
      const userStore = useUserStore();

      if (!userStore.activeOrgId) {
        return { containers: [], total: 0, page: 1, per_page: 20 };
      }
      const { $ofetch }: any = useNuxtApp();
      const url = `/api/vessels/cbolb?id=${id}`;
      const data: any = await $ofetch(url, { method: 'GET' });
      return data;
    },
    setFiltersSideModalStatus(status: boolean) {
      this.showFiltersSideModal = status;
    },
    async uploadVessel(body: any) {
      const { $ofetch }: any = useNuxtApp();
      const runtimeConfig = useRuntimeConfig();
      const userStore = useUserStore();
      const { obj }: any = await $ofetch(`${runtimeConfig.public.API_URL}orgs/${userStore.activeOrgId}/eta/vessel-bookmarks`, {
        method: 'POST', body,
      });
      return obj;
    },
  },
});
