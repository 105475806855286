import moment from 'moment/moment';

export const parseVesselBookmark = (vessel: any): any => {
  return vessel;
};

export const prepareQueryParamsForVesselRequest = (query: any, org_id: string): any => {
  const queryParams: any = {
    q: '*',
    page: query.page || 1,
    per_page: 20,
    filter_by: `org_id:=${org_id}&&deleted:=false`,
  };

  if (query.q) {
    queryParams.q = query.q;
  }
  if (query.tab) {
    if (query.tab === 'ALL') {
      //
    }
    else if (query.tab === 'NEW') {
      queryParams.filter_by += `&&created:[${moment().subtract(1, 'days').unix()}..${moment().unix()}]`;
    }
    else if (query.tab === 'EARLY') {
      queryParams.filter_by += '&&arrival_status:[early]&&arrived:=false';
    }
    else if (query.tab === 'LATE') {
      queryParams.filter_by += '&&arrival_status:[late]&&arrived:=false';
    }
    else if (query.tab === 'ON_TIME') {
      queryParams.filter_by += '&&arrival_status:[on time]&&arrived:=false';
    }
    else if (query.tab === 'ON_THE_WAY') {
      queryParams.filter_by += '&&arrived:=false';
    }
  }
  // if (filterAndJoinCommaSeparated(query.carrier)) {
  //   queryParams.filter_by += `&&carrier_no:[${filterAndJoinCommaSeparated(query.carrier)}]`;
  // }
  // if (filterAndJoinCommaSeparated(query.vessel)) {
  //   queryParams.filter_by += `&&vessel_imos:[${filterAndJoinCommaSeparated(query.vessel)}]`;
  // }
  // if (filterAndJoinCommaSeparated(query.status)) {
  //   queryParams.filter_by += `&&arrival_status_code:[${filterAndJoinCommaSeparated(query.status)}]`;
  // }
  // if (filterAndJoinCommaSeparated(query.poNumber)) {
  //   queryParams.filter_by += `&&po_number:[${query.poNumber}]`;
  // }
  // if (filterAndJoinCommaSeparated(query.blNumber)) {
  //   queryParams.filter_by += `&&bl_no:[${query.blNumber}]`;
  // }
  // if (validateAndTrimString(query.lateStatus)) {
  //   queryParams.filter_by += `&&arrival_status_code:[late]&&
  //   pod_arrival_prediction_vs_original_etd_delta:${validateAndTrimString(query.lateStatus)}`;
  // }
  const queryParamString = Object.keys(queryParams)
    .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
  return (queryParamString);
};
